import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');

    return tokenString?true:false
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', true);
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}
