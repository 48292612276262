import React from "react";
import { observer } from "mobx-react-lite";
import { InputGroup } from "@blueprintjs/core";
import { useInfiniteAPI } from "polotno/utils/use-api";
import { SectionTab } from "polotno/side-panel";
import { svgToURL } from "polotno/utils/svg";
import { getKey } from "polotno/utils/validate-key";
import { getImageSize } from "polotno/utils/image";
import FaImages from "@meronex/icons/fa/FaImages";
import { ImagesGrid } from "polotno/side-panel/images-grid";
export const CustomTemplatePanel = observer(({ store }) => {
  // load data
  const { data, isLoading, loadMore, setQuery } = useInfiniteAPI({
    getAPI: ({ page, query }) =>
      `https://stage.i3lany.com/api/design-templates?page=${page}`,
    getSize: (res) => Math.floor(res.count / res.limit),
  });

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: "20px",
        }}
      />
      <ImagesGrid
        shadowEnabled={false}
        images={data?.map((data) => data.data).flat()}
        getPreview={(item) => item.preview}
        isLoading={isLoading}
        onSelect={async (item, pos, element) => {
          // download selected json
          const req = await fetch(item.json_file);
          console.log(item);
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        rowsNumber={2}
        loadMore={loadMore}
      />
    </div>
  );
});

// define the new custom section
export const CustomTemplateSection = {
  name: "custom-template-apis",
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <FaImages />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: CustomTemplatePanel,
};
