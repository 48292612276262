import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from "@material-ui/core/Snackbar";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './Login.css';

 function loginUser(credentials) {
 return true

}
// const loginUser = (credentials)=>{


// }

export default function Login({ setToken }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit =  e => {e.preventDefault();
   // console.log(username);
    if(username === "admin" && password === "P@ssw0rd"){
      const token =  loginUser({username,password});
      setToken(token);
      window.location.reload(false);

  }else if (username || password){
    handleClick();
  }
  }
  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <Snackbar
      anchorOrigin={{
        horizontal: "left",
        vertical: "top",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Please Enter Valid Password"
      action={action}
    />


      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes =  {
  setToken: PropTypes.func.isRequired
};
