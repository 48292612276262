import React from "react";
import ReactDOM from "react-dom";
import localforage from "localforage";
import { createStore } from "polotno/model/store";
import { unstable_setRemoveBackgroundEnabled } from "polotno/config";

import "./index.css";
import App from "./App";

unstable_setRemoveBackgroundEnabled(true);

const store = createStore({ key: "tJ2fcTN_WlfHciqhtcKt" });
window.store = store;

localforage.getItem("polotno-state", function (err, json) {
  if (json) {
    store.loadJSON(json);
  }
  if (!store.pages.length) {
    store.addPage();
  }
});

store.on("change", () => {
  try {
    const json = store.toJSON();
    localforage.setItem("polotno-state", json);
  } catch (e) {}
});

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById("root")
);
