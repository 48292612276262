import React from "react";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import {
  BackgroundSection,
  ElementsSection,
  PhotosSection,
  SidePanel,
  TextSection,
  UploadSection,
  SizeSection,
} from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import { loadFile } from "./file";

import Login  from './login/Login';
import useToken from "./useToken";

import Topbar from "./topbar";
import { CustomTemplateSection } from "./custom-templates-sidepanel";

const useHeight = () => {
  const [height, setHeight] = React.useState(window.innerHeight);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight);
    });
  }, []);
  return height;
};

const App = ({ store }) => {
  const { token, setToken } = useToken();


  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === "file") {
          const file = ev.dataTransfer.items[i].getAsFile();
          loadFile(file, store);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        loadFile(ev.dataTransfer.files[i], store);
      }
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const height = useHeight();
if(!token){
  return <Login setToken={setToken} />
}
  return (


    <div
      style={{
        width: "100vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
    <BrowserRouter>

    <Switch>



<Route excat path="/">
<Topbar store={store} />
<div style={{ height: "calc(100% - 50px)" }}>
<PolotnoContainer className="polotno-app-container">
  <SidePanelWrap>
    <SidePanel
      store={store}
      sections={[
        CustomTemplateSection,
        TextSection,
        PhotosSection,
        ElementsSection,
        UploadSection,
        BackgroundSection,
        SizeSection,
      ]}
    />
  </SidePanelWrap>
  <WorkspaceWrap>
    <Toolbar store={store} />
    <Workspace store={store} />
    <ZoomButtons store={store} />
  </WorkspaceWrap>
</PolotnoContainer>
</div>
</Route>
</Switch>
</BrowserRouter>




    </div>
  );
};

export default App;
